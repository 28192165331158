import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AntiCheatCheckModel from '@Api/models/AntiCheatCheckModel';
import CompetitionResultModel from '@Api/models/CompetitionResultModel';
import { TypingMode } from '@Api/models/TypingTestResultModel';
import { useCompleteAntiCheatCheck, useGetAntiCheatImage } from '@Api/services/AntiCheatService';
import { ButtonModifier, ButtonType } from '@Components/atoms/Button/Button';
import CountdownSpinner from '@Components/atoms/CountdownSpinner';
import { PropsWithClassName } from '@Components/helper';
import { unexpectedApiError } from '@Components/helper/error';
import { AntiCheatGameResult, getLanguageFromAntiCheatGameResult } from '@Helpers/result';
import { Controls, CountdownWrapper, Input, InputBox, InputWithControls, InputWithControlsBorder, Refresh, Root, StyledButton, StyledIconRefresh, StyledImage, StyledInputText } from './AntiCheatTypingBox.styles';
interface Props extends PropsWithClassName {
  result: AntiCheatGameResult;
  maxHeight: number;
  maxWidth: number;
  onReset: () => void;
  onFailure: () => void;
  onSuccess: (reachedWpm: number) => void;
}
const AntiCheatTypingBox = (props: Props): React.ReactElement => {
  const {
    t,
    i18n
  } = useTranslation('global');
  const {
    result,
    maxWidth,
    maxHeight,
    onReset,
    onFailure,
    onSuccess
  } = props;
  const [antiCheatImage, setAntiCheatImage] = useState<string | null>(null);
  const [text, setText] = useState<string>('');
  const [isCountdownFinished, setIsCountdownFinished] = useState<boolean>(false);
  const [isComposing, setIsComposing] = useState<boolean>(false);
  const languageIso = useMemo(() => {
    return getLanguageFromAntiCheatGameResult(result).iso;
  }, [result]);
  const typingMode = useMemo(() => {
    if (result instanceof CompetitionResultModel) {
      return TypingMode.Normal;
    }
    return result.typingMode;
  }, [result]);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    executeRequest: completeAntiCheatExecuteRequest
  } = useCompleteAntiCheatCheck((antiCheatCheckModel: AntiCheatCheckModel) => {
    setAntiCheatImage(null);
    setText('');
    if (antiCheatCheckModel.maxReachedWpm > result.testResult.wpm) {
      onSuccess(antiCheatCheckModel.maxReachedWpm);
      return;
    }
    onFailure();
  });
  const completeAntiCheatCheck = useCallback(async () => {
    await completeAntiCheatExecuteRequest({
      body: {
        typingMode,
        languageIso,
        words: text
      }
    });
  }, [completeAntiCheatExecuteRequest, text, languageIso, typingMode]);
  useLayoutEffect(() => {
    if (!isCountdownFinished) {
      return;
    }
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.code === 'Enter' && !isComposing) {
        completeAntiCheatCheck().catch(unexpectedApiError);
      }
    };
    window.addEventListener('keydown', handleEscapeKey);
    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, [completeAntiCheatCheck, isCountdownFinished, isComposing]);
  useEffect(() => {
    inputRef.current?.focus();
  }, [isCountdownFinished]);
  const {
    executeRequest: getImageExecuteRequest
  } = useGetAntiCheatImage(result => {
    const image = URL.createObjectURL(result);
    setAntiCheatImage(image);
  });
  useEffect(() => {
    const getAntiCheatImage = async () => {
      await getImageExecuteRequest({
        parseAs: 'blob',
        path: {
          typingMode,
          languageIso
        }
      });
    };
    getAntiCheatImage().catch(unexpectedApiError);
  }, [result, getImageExecuteRequest, typingMode, languageIso]);
  const handleReset = (): void => {
    onReset();
    setAntiCheatImage(null);
    setText('');
  };
  const renderContent = () => {
    if (!antiCheatImage || !isCountdownFinished) {
      return <CountdownWrapper>
          <CountdownSpinner loading={antiCheatImage === null} initialCount={3} onFinish={() => setIsCountdownFinished(true)} />
        </CountdownWrapper>;
    }
    return <StyledImage src={antiCheatImage} maxWidth={maxWidth} maxHeight={maxHeight} data-sentry-element="StyledImage" data-sentry-component="renderContent" data-sentry-source-file="AntiCheatTypingBox.tsx" />;
  };
  return <Root className={props.className} data-testid={props.dataTestId ?? 'AntiCheatTypingBox-root'} data-sentry-element="Root" data-sentry-component="AntiCheatTypingBox" data-sentry-source-file="AntiCheatTypingBox.tsx">
      <InputBox data-sentry-element="InputBox" data-sentry-source-file="AntiCheatTypingBox.tsx">
        {renderContent()}
        <Input data-sentry-element="Input" data-sentry-source-file="AntiCheatTypingBox.tsx">
          <InputWithControlsBorder data-sentry-element="InputWithControlsBorder" data-sentry-source-file="AntiCheatTypingBox.tsx">
            <InputWithControls data-sentry-element="InputWithControls" data-sentry-source-file="AntiCheatTypingBox.tsx">
              <StyledInputText ref={inputRef} autoCapitalize="off" placeholder={t('anti_cheat.input.placeholder') ?? undefined} data-testid={`AntiCheatTyping-box-input`} disabled={antiCheatImage === null || !isCountdownFinished} value={text} dir={i18n.dir(languageIso)} onChange={event => setText(event.target.value)} onCompositionStart={() => setIsComposing(true)} onCompositionEnd={() => setIsComposing(false)} data-sentry-element="StyledInputText" data-sentry-source-file="AntiCheatTypingBox.tsx" />
              <Controls data-sentry-element="Controls" data-sentry-source-file="AntiCheatTypingBox.tsx">
                <Refresh onClick={handleReset} data-sentry-element="Refresh" data-sentry-source-file="AntiCheatTypingBox.tsx">
                  <StyledIconRefresh data-sentry-element="StyledIconRefresh" data-sentry-source-file="AntiCheatTypingBox.tsx" />
                </Refresh>
              </Controls>
            </InputWithControls>
          </InputWithControlsBorder>
        </Input>
      </InputBox>
      {antiCheatImage && isCountdownFinished && <StyledButton type={ButtonType.Button} modifier={ButtonModifier.Primary} onClick={completeAntiCheatCheck}>
          {t('anti_cheat.button.finish')}
        </StyledButton>}
    </Root>;
};
export default AntiCheatTypingBox;