import React, { useEffect, useState } from 'react';
import { PropsWithClassName } from '@Components/helper';
import { Count, Root, TimerLt, TimerRt, TimerSlot } from './CountdownSpinner.styles';
interface Props extends PropsWithClassName {
  initialCount: number;
  timerStartCount?: number;
  onFinish: () => void;
  loading: boolean;
}
const CountdownSpinner = (props: Props): React.ReactElement => {
  const {
    initialCount,
    onFinish,
    loading,
    timerStartCount
  } = props;
  const [count, setCount] = useState<number>(timerStartCount ?? initialCount);
  useEffect(() => {
    const interval = setInterval(() => {
      if (loading) {
        return;
      }
      const newCount = count - 1;
      if (newCount <= 0) {
        onFinish();
        return;
      }
      setCount(newCount);
    }, 1000);
    return () => clearInterval(interval);
  }, [count, onFinish, loading]);
  const renderContent = () => {
    if (loading) {
      return <></>; // todo: spinner while loading?
    }
    return <>
        <TimerSlot data-sentry-element="TimerSlot" data-sentry-source-file="CountdownSpinner.tsx">
          <TimerLt duration={initialCount} initialProgress={timerStartCount ? initialCount - timerStartCount : 0} data-sentry-element="TimerLt" data-sentry-source-file="CountdownSpinner.tsx"></TimerLt>
        </TimerSlot>
        <TimerSlot data-sentry-element="TimerSlot" data-sentry-source-file="CountdownSpinner.tsx">
          <TimerRt duration={initialCount} initialProgress={timerStartCount ? initialCount - timerStartCount : 0} data-sentry-element="TimerRt" data-sentry-source-file="CountdownSpinner.tsx"></TimerRt>
        </TimerSlot>
        <Count data-sentry-element="Count" data-sentry-source-file="CountdownSpinner.tsx">{count}</Count>
      </>;
  };
  return <Root className={props.className} data-testid={props.dataTestId ?? 'CountdownSpinner-root'} data-sentry-element="Root" data-sentry-component="CountdownSpinner" data-sentry-source-file="CountdownSpinner.tsx">
      {renderContent()}
    </Root>;
};
export { CountdownSpinner };